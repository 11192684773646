import Icon_EmptyState from '@capturi/assets/images/EmptyState.svg'
import { useCurrentUser } from '@capturi/core'
import { DashboardAdminView, useDashboard } from '@capturi/dashboard'
import { FilterPeriodProvider, PeriodDefinitions } from '@capturi/filters'
import { usePageTitle } from '@capturi/react-utils'
import { ContentPlaceholder, useToast } from '@capturi/ui-components'
import { Trans, t } from '@lingui/macro'
import { withPeriodSyncToSearchParams } from 'features/filters/filter-period-enhancers'
import React from 'react'
import { useNavigate, useParams } from 'react-router'

import dashboardRoutes from './routes'

const FilterPeriodProviderWithSync = withPeriodSyncToSearchParams(
  FilterPeriodProvider,
  'dashboard_period',
)

export default function DashboardAdminPage(): React.ReactElement | null {
  usePageTitle(t`Dashboard editor`)
  const params = useParams()
  const navigate = useNavigate()
  const currentUser = useCurrentUser()
  const toast = useToast()
  const { dashboard } = useDashboard({
    uid: params.uid,
  })

  if (params.uid == null) {
    return null
  }

  if (dashboard?.accessLevel !== 'Edit') {
    return null
  }

  // This statement also needs to check if the board is shared as teeam or user. The empty state also need to perfected.
  if (
    dashboard?.shared &&
    dashboard?.sharedContextType !== 'Organization' &&
    currentUser.isTeamLead &&
    (!currentUser.teamLeadTeamUids ||
      currentUser.teamLeadTeamUids?.length === 0)
  ) {
    return (
      <ContentPlaceholder.Container mt={20}>
        <ContentPlaceholder.Image as={Icon_EmptyState} />
        <ContentPlaceholder.Heading>
          <Trans>🧸 You are team lead for...</Trans>
        </ContentPlaceholder.Heading>
        <ContentPlaceholder.Body>
          <Trans>
            No one. Get your administrator to assign you to lead a team and you
            will see your team&apos;s data here.
          </Trans>
        </ContentPlaceholder.Body>
      </ContentPlaceholder.Container>
    )
  }
  return (
    <FilterPeriodProviderWithSync defaultPeriod={PeriodDefinitions.Last30Days}>
      <DashboardAdminView
        uid={params.uid}
        onDashboardDeleted={() => navigate(dashboardRoutes.list())}
        onDashboardNotFound={() => {
          toast({
            status: 'error',
            title: t`Dashboard not found`,
          })
          navigate(dashboardRoutes.list(), { replace: true })
        }}
        onDashboardCloned={(dashboard) => {
          navigate(dashboardRoutes.editor(dashboard.uid))
        }}
        onDashboardChange={(dashboard) => {
          navigate(dashboardRoutes.editor(dashboard.uid))
        }}
      />
    </FilterPeriodProviderWithSync>
  )
}
