import {
  InaccessibleSavedTextFilter,
  SavedTextFilter,
  TextFilterValues,
  useAllSavedFilters,
  useAllSavedTextFilters,
} from '@capturi/api-filters'
import { useFeatureFlags } from '@capturi/feature-flags'
import {
  SegmentStatesProvider,
  toDuration,
  toTextFilterValues,
  useSegmentStatesContext,
} from '@capturi/filters'
import { ErrorBoundary, withSuspense } from '@capturi/react-utils'
import { getErrorMessage } from '@capturi/request'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useToast,
} from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import { Stack, Text, Tooltip } from '@chakra-ui/react'
import { DevTool } from '@hookform/devtools'
import { Trans, t } from '@lingui/macro'
import React from 'react'
import { useFormContext, useFormState } from 'react-hook-form'

import { useCurrentUser } from '@capturi/core'
import { useDashboard } from '../../hooks'
import { useInaccessibleFilterValues } from '../../hooks/useInaccessibleFilterValues'
import { WidgetCreateEditResult, WidgetModel } from '../../types'
import { ConfiguratorContext } from '../../widgets/configurator'
import {
  FormModel,
  toFormModel,
  toWidgetModel,
} from '../../widgets/configurator/formUtils'
import Registry from '../../widgets/registry'

export interface EditWidgetDialogOptions {
  dashboardUid: string
  widget: WidgetModel
}

type EditWidgetDialogProps = EditWidgetDialogOptions &
  BaseModalComponentProps<WidgetCreateEditResult>

const EditWidgetDialog: React.FC<EditWidgetDialogProps> = (props) => {
  const { dashboardUid, widget, isOpen = false, onClose } = props
  const { actions } = useDashboard({ uid: dashboardUid })
  const toast = useToast()

  const { states } = useSegmentStatesContext()

  const initialFormModel = toFormModel({
    ...props.widget,
  })

  const definition = Registry.get(widget.type)
  const Configurator = definition?.Configurator
  if (Configurator == null) return null

  const onSubmit = async (values: FormModel<WidgetModel>): Promise<void> => {
    try {
      const model = {
        ...toWidgetModel(values, states[0].channel),
        type: widget.type,
      }
      const updatedWidget = await actions.updateWidget(widget.uid, model, {
        /**
         * Certain widgets (e.g. HitRate) will receive different data depending on their
         * configuration, therefore it is important not to update the cache optimistically
         * when editing a widget. If the cache is updated optimistically then the cache
         * might respond with data that does not conform to the format the given widget expects
         * Fx. changing widget `visual` on the HitRate widget from 'number', where response data is
         * an object of key/number-values pairs, to 'time-series' where response data is expected to have a `series` key with an array value.
         */
        optimistic: false,
      })
      props.onSubmit?.(updatedWidget)
    } catch (error) {
      const errorMsg = getErrorMessage(error)
      toast({
        title: t`The widget was not updated`,
        description: errorMsg,
        status: 'error',
      })
    }
  }

  const context = {
    isEditMode: true,
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay>
        <ModalContent>
          <ModalHeader borderBottom="1px solid" borderColor="gray.200">
            <Trans>Edit widget</Trans>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p="0">
            <ErrorBoundary>
              <React.Suspense
                fallback={<Spinner display="block" m="4rem auto" />}
              >
                <ConfiguratorContext.Provider value={context}>
                  <Configurator
                    formModel={initialFormModel}
                    definition={definition}
                    onSubmit={onSubmit}
                  >
                    <FormActions onCancel={onClose} />
                  </Configurator>
                </ConfiguratorContext.Provider>
              </React.Suspense>
            </ErrorBoundary>
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

const FormActions: React.FC<{
  onCancel: () => void
}> = ({ onCancel }) => {
  const { isSubmitting } = useFormState()
  const { control } = useFormContext()
  const { isInvalid } = useInaccessibleFilterValues()
  return (
    <>
      {isInvalid && (
        <Text fontStyle="italic" mt={5} ml={5} color="warning" float="left">
          *You need to remove one or more filters before being able to save
          changes.
        </Text>
      )}
      <Stack direction="row" spacing={4} justify="flex-end" p={4}>
        <Button secondary type="button" onClick={onCancel}>
          <Trans>Cancel</Trans>
        </Button>
        <Tooltip
          label={t`You don’t have view rights for one or more selected filters. Remove or change this filter before you can save changes to the widget.`}
          hasArrow
          placement="top"
          isDisabled={!isInvalid}
        >
          <Button primary type="submit" isDisabled={isSubmitting || isInvalid}>
            <Trans>Save</Trans>
          </Button>
        </Tooltip>
        {process.env.NODE_ENV === 'development' && (
          <DevTool control={control} placement="top-right" />
        )}
      </Stack>
    </>
  )
}

function widgetTextFilterToSavedTextFilter(
  widgetTextFilter: WidgetModel['textFilters'],
  savedTextFilters:
    | (SavedTextFilter | InaccessibleSavedTextFilter)[]
    | undefined,
): [
  SavedTextFilter | InaccessibleSavedTextFilter | undefined,
  TextFilterValues | undefined,
] {
  let savedTextFilterUid: string | undefined
  let textFilterValues: TextFilterValues | undefined
  if (widgetTextFilter) {
    if (
      'savedCaseFilterUid' in widgetTextFilter &&
      widgetTextFilter.savedCaseFilterUid != null
    ) {
      savedTextFilterUid = widgetTextFilter.savedCaseFilterUid
    } else {
      textFilterValues = toTextFilterValues(widgetTextFilter)
    }
  }
  const savedTextFilter = savedTextFilterUid
    ? savedTextFilters?.find(
        (savedTextFilter) => savedTextFilter.uid === savedTextFilterUid,
      )
    : undefined

  if (savedTextFilter && savedTextFilter.accessLevel !== 'None') {
    textFilterValues = toTextFilterValues(savedTextFilter.values)
  }

  return [savedTextFilter, textFilterValues]
}

const WidgetDialogWrapper: React.FC<EditWidgetDialogProps> = (props) => {
  const { data: savedFilters } = useAllSavedFilters()
  const { enableText } = useFeatureFlags()
  const { permissions } = useCurrentUser()

  const areTextFiltersAvailable = enableText && permissions.text
  const channel =
    props.widget.filters !== null
      ? 'phone'
      : areTextFiltersAvailable
        ? 'email'
        : 'phone'

  const savedFilter = savedFilters?.find(
    (savedFilter) =>
      savedFilter.uid === props.widget.filters?.savedFilterGroupUid,
  )

  const savedSharedFilter = savedFilters?.find(
    (savedFilter) =>
      savedFilter.uid === props.widget.shareFilter?.savedFilterGroupUid,
  )

  const { data: savedTextFilters, isLoading: isLoadingSavedTextFilters } =
    useAllSavedTextFilters({
      enabled: areTextFiltersAvailable,
    })
  const [savedTextFilter, textFilterValues] = widgetTextFilterToSavedTextFilter(
    props.widget.textFilters,
    savedTextFilters,
  )
  const [savedTextShareFilter, textShareFilterValues] =
    widgetTextFilterToSavedTextFilter(
      props.widget.textShareFilter,
      savedTextFilters,
    )

  const phoneFilterValues = savedFilter
    ? savedFilter.values
    : props.widget.filters
  const phoneSubfilterValues = savedSharedFilter
    ? savedSharedFilter.values
    : props.widget.shareFilter

  if (areTextFiltersAvailable && isLoadingSavedTextFilters) {
    return null
  }

  return (
    <SegmentStatesProvider
      initialState={
        channel === 'phone'
          ? {
              channel: 'phone',
              state: {
                values: {
                  ...phoneFilterValues,
                  duration: toDuration(
                    phoneFilterValues?.duration?.min,
                    phoneFilterValues?.duration?.max,
                  ),
                },
                savedFilter: savedFilter,
              },
              subfilterState: {
                values: {
                  ...phoneSubfilterValues,
                  duration: toDuration(
                    phoneSubfilterValues?.duration?.min,
                    phoneSubfilterValues?.duration?.max,
                  ),
                },
                savedFilter: savedSharedFilter,
              },
            }
          : {
              channel: 'email',
              state: {
                savedFilter: savedTextFilter,
                values: textFilterValues,
              },
              subfilterState: {
                savedFilter: savedTextShareFilter,
                values: textShareFilterValues,
              },
            }
      }
    >
      <EditWidgetDialog {...props} />
    </SegmentStatesProvider>
  )
}

export default withSuspense(WidgetDialogWrapper)
