import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import { FormControl, FormLabel, Input, Stack } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React from 'react'
import { useForm } from 'react-hook-form'

export type CloneDashboardDialogOptions = {
  defaultTitle?: string
  defaultDescription?: string
}

export type CloneDashboardDialogResult = {
  title: string
  description: string
}

export type CloneDashboardDialogProps = CloneDashboardDialogOptions &
  BaseModalComponentProps<CloneDashboardDialogResult>

export const CloneDashboardDialog: React.FC<CloneDashboardDialogProps> = ({
  isOpen = false,
  onClose,
  onSubmit: onSubmitProp,
  defaultTitle,
  defaultDescription,
}) => {
  const titleRef = React.useRef<HTMLInputElement>(null)

  const { handleSubmit, register } = useForm<CloneDashboardDialogResult>({
    defaultValues: {
      title: defaultTitle,
      description: defaultDescription,
    },
  })

  const onSubmit = (data: CloneDashboardDialogResult): void => {
    onSubmitProp?.(data)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} initialFocusRef={titleRef}>
      <ModalOverlay>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalContent>
            <ModalCloseButton />
            <ModalHeader>
              <Trans>Clone dashboard</Trans>
            </ModalHeader>
            <ModalBody>
              <Stack spacing={4}>
                <FormControl isRequired>
                  <FormLabel htmlFor="dashboard-title">
                    <Trans>Title on cloned dashboard</Trans>
                  </FormLabel>
                  <Input
                    placeholder={t`Title`}
                    {...register('title', { required: true })}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="dashboard-description">
                    <Trans>Description</Trans>
                  </FormLabel>
                  <Input
                    placeholder={t`Description`}
                    {...register('description')}
                  />
                </FormControl>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Stack direction="row" spacing={4} justify="flex-end">
                <Button secondary type="button" onClick={onClose}>
                  <Trans>Cancel</Trans>
                </Button>
                <Button primary type="submit">
                  <Trans>Clone dashboard</Trans>
                </Button>
              </Stack>
            </ModalFooter>
          </ModalContent>
        </form>
      </ModalOverlay>
    </Modal>
  )
}
